import { __assign, __awaiter, __generator } from "tslib";
import { app } from '@ekuaibao/whispered';
import { batchResult } from './viewUtil';
export function deliverFlow(_a) {
    var flowIds = _a.flowIds, privilegeId = _a.privilegeId, isBatch = _a.isBatch;
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_b) {
            return [2 /*return*/, doAction({ actionName: 'freeflow.addnode', flowIds: flowIds, privilegeId: privilegeId, type: 'SHIFT_NODE', isBatch: isBatch })];
        });
    });
}
export function backFlow(_a) {
    var flowIds = _a.flowIds, privilegeId = _a.privilegeId, isBatch = _a.isBatch;
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_b) {
            return [2 /*return*/, doAction({ actionName: 'freeflow.back', flowIds: flowIds, privilegeId: privilegeId, type: 'rollback', isBatch: isBatch })];
        });
    });
}
function doAction(_a) {
    var actionName = _a.actionName, flowIds = _a.flowIds, type = _a.type, privilegeId = _a.privilegeId, isBatch = _a.isBatch;
    return __awaiter(this, void 0, void 0, function () {
        var title, modal, options, data, result, e_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    title = i18n.get('转交单据');
                    modal = 'DeliverModal';
                    if (actionName === 'freeflow.back') {
                        title = i18n.get('回退单据');
                        modal = 'FlowBackModal';
                    }
                    return [4 /*yield*/, app.open("@order-manage:" + modal, {
                            isBatch: isBatch,
                            flowIds: flowIds,
                            privilegeId: privilegeId,
                            modalTitle: title,
                            showAutograph: true
                        })];
                case 1:
                    options = _b.sent();
                    data = {
                        flowIds: flowIds,
                        action: __assign(__assign({ name: actionName }, options), { privilegeId: privilegeId,
                            type: type })
                    };
                    _b.label = 2;
                case 2:
                    _b.trys.push([2, 4, , 5]);
                    return [4 /*yield*/, app.invokeService('@bills:flowDoAction', data)];
                case 3:
                    result = _b.sent();
                    return [2 /*return*/, batchResult(result, actionName)];
                case 4:
                    e_1 = _b.sent();
                    return [2 /*return*/, Promise.reject(e_1)];
                case 5: return [2 /*return*/];
            }
        });
    });
}
