/*
 * @Author: Onein
 * @Date: 2018-08-27 15:29:40
 * @Last Modified by: Onein
 * @Last Modified time: 2019-08-09 18:18:32
 */

import { deliverFlow, backFlow } from './util/flowAction'

export default [
  {
    id: '@order-manage',
    reducer: () => import('./order-manage.reducer'),
    path: '/order-manage/:id',
    ref: '/',
    onload: async app => {
      await app.invokeService('@permission:getMinePrivilegePermissions')
      return import('./order-manage-view')
    },
    async onfirst(app) {
      app.invokeService('@common:get:staffs:blacklist')
      app.invokeService('@common:get:roles')
    },
    backFlow: backFlow,
    deliverFlow: deliverFlow
  },
  {
    point: '@@menus',
    onload: () => [
      {
        id: 'order-manage',
        pId: 'financial',
        href: '/order-manage/:id',
        __hidden: true,
        __routeable: true
      }
    ]
  },
  {
    point: '@@layers',
    prefix: '@order-manage',
    onload: () => import('./layers')
  }
]
