import React from 'react';
import { app } from '@ekuaibao/whispered';
import { get } from 'lodash';
export function batchResult(result, type) {
    return new Promise(function (resolve, reject) {
        var totalCount = get(result, 'value.total');
        var successCount = get(result, 'value.success');
        var title = i18n.get('转交完成');
        if (type === 'freeflow.back') {
            title = i18n.get('回退完成');
        }
        var confirm = function () {
            return successCount === totalCount ? resolve(result) : reject(result);
        };
        app.open('@audit:ApprovalCompleteModal', {
            label: React.createElement(BatchModalTitle, { value: result.value, title: title }),
            details: React.createElement(BatchModalContent, { value: result.value }),
            handleConfirm: confirm
        });
    });
}
export var BatchModalTitle = function (props) {
    var title = props.title, value = props.value;
    var error = value.error, success = value.success;
    var errorStr = '';
    if (!!error) {
        errorStr = i18n.get("\uFF0C{__k0}{__k1}", { __k0: error, __k1: i18n.get('条失败') });
    }
    return (React.createElement("div", { className: "batch-modal-title-wrapper" },
        React.createElement("div", { className: "title" }, title),
        React.createElement("div", { className: "title-content" }, "" + success + i18n.get('条成功') + errorStr)));
};
export var BatchModalContent = function (props) {
    var value = props.value;
    var messages = value.messages;
    if (!messages || !messages.length) {
        return React.createElement(React.Fragment, null);
    }
    return (React.createElement("div", { className: "batch-modal-message-wrapper" }, messages.map(function (item, index) {
        return (React.createElement("div", { className: "batch-item color-gray", key: index },
            React.createElement("span", { className: "mark" }),
            "\u00A0\u00A0",
            React.createElement("span", null, item)));
    })));
};
